/**
 * User Context
 *
 * The React Context for Employer Portal.
 * @link https://reactjs.org/docs/context.html
 */
import { createContext } from 'react';
// Lib
import { CredentialResponse } from '@react-oauth/google';
// Models
import UserModel from '../@types/models/UserModel';

export interface UserContextType {
  userModel?: null | UserModel;
  googleSignInSuccess?: null | ((credentialResponse: CredentialResponse) => void);
}

const UserContext = createContext<UserContextType>({
  userModel: null,
  googleSignInSuccess: null,
});
export default UserContext;
